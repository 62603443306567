import { type Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/redirectHelpers'

const idpPlansChildRoutes = [
  {
    path: '',
    name: 'idp-plans-list' as const,
    component: () => import('@/views/IdpPlansList/IdpPlansListView.vue'),
    meta: {
      title: 'IDP Plans list'
    }
  }
] satisfies Route[]

export const idpPlansRoutes = [
  {
    path: 'idp-plans',
    name: 'idp-plans' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'IDP Plans',
      icon: 'radar-chart',
      permissions: ['students_idp.view_studentidpplan']
    },
    redirect: () => getFirstPossibleRoute(idpPlansChildRoutes),
    children: idpPlansChildRoutes
  }
] satisfies Route[]
