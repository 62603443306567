import axios from 'axios'

import Service, { prepareURL } from '@/api/service'

export const Settings = {
  update: payload => {
    const url = '/admin/core/settings/'
    return axios.put(url, payload)
  },
  get: () => {
    const url = '/admin/core/settings/'
    return axios.get(url)
  }
}

export const AdminTimetable = {
  fetch: (urlParams, payload) =>
    axios.get(prepareURL('/admin-timetable/', urlParams), payload),
  downloadPdf(params) {
    const url = `/admin-timetable/pdf/`
    return axios.get(url, {
      params,
      responseType: 'blob'
    })
  }
}

export const Lesson = {
  ...Service('/admin/activities/lesson/:id/'),
  roomUpdate(lessonId, payload) {
    const url = `/admin/activities/lesson/${lessonId}/assign-room/`
    return axios.put(url, payload)
  },
  splitLesson(lessonId) {
    const url = `/admin/activities/lesson/${lessonId}/split-lesson/`
    return axios.post(url)
  }
}

export const Teacher = {
  ...Service('/admin/teachers/:id/'),
  countedLessons(params) {
    const url = '/admin/teachers/counted-lessons/'
    return axios.get(url, { params })
  }
}

export const User = {
  ...Service('/admin/users/:id/'),
  adminChangePassword: payload => {
    const userId = payload.userId
    return axios.post(`/admin/users/${userId}/set-password/`, payload)
  },
  toggleActive: urlParams => {
    const url = '/admin/users/:id/toggle-active/'
    return axios.post(prepareURL(url, urlParams))
  },
  profiles() {
    const url = '/admin/users/profiles'
    return axios.get(url)
  },
  staffTypes() {
    return axios.get('/admin/users/types/')
  },
  genders() {
    return axios.get('/admin/users/genders/')
  },
  teachersAndTutors() {
    return axios.get('/admin/users/teachers-and-tutors/')
  }
}

export const ImportTimetable = {
  ...Service('/admin/importing/celery-task-status/:id/'),
  import: (urlParams, payload) => {
    const url = '/admin/importing/import-timetable/:id/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  fetch: (urlParams, payload, nextUrl) => {
    const url =
      nextUrl ||
      prepareURL('/admin/importing/celery-task-status/?page_size=5', urlParams)
    return axios.get(url, payload)
  },
  cancelTask: taskId => {
    const url = `/admin/importing/celery-task-status/${taskId}/revoke-task/`
    return axios.post(url)
  }
}

export const AdminSchoolYear = Service('/admin/periods/years/:id/')
