import type { Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/redirectHelpers'

const studentContactsChildRoutes = [
  {
    path: '',
    name: 'student-contact-list' as const,
    component: () =>
      import('@/views/StudentContacts/StudentContactListView.vue'),
    meta: {
      title: 'Student Contacts',
      permissions: ['parents.view_parent']
    }
  },
  {
    path: 'add',
    name: 'student-contact-add' as const,
    component: () => import('@/views/StudentContacts/StudentContactForm.vue'),
    meta: {
      title: 'Student Contact add',
      permissions: ['parents.change_parent']
    }
  },
  {
    path: ':studentContactId',
    name: 'student-contact-details' as const,
    component: () =>
      import('@/views/StudentContacts/StudentContactDetails.vue'),
    meta: {
      title: 'Student Contact details',
      permissions: ['parents.view_parent']
    }
  },
  {
    path: ':studentContactId/edit',
    name: 'student-contact-edit' as const,
    component: () => import('@/views/StudentContacts/StudentContactForm.vue'),
    meta: {
      title: 'Student Contact edit',
      permissions: ['parents.change_parent']
    }
  }
] satisfies Route[]

export const studentContactsRoutes = [
  {
    path: 'student-contacts',
    name: 'student-contacts' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Student Contacts',
      icon: 'team'
    },
    redirect: () => getFirstPossibleRoute(studentContactsChildRoutes),
    children: studentContactsChildRoutes
  }
] satisfies Route[]
