import { getFirstPossibleRoute } from '@/router/utils/redirectHelpers'
import { type Route } from '@/types/route'

const teamsChildrenRoutes = [
  {
    path: '',
    name: 'team-list' as const,
    component: () => import('@/views/Teams/TeamsView.vue'),
    meta: {
      title: 'Teams',
      permissions: ['teams.view_team']
    }
  }
] satisfies Route[]

export const teamsRoutes = [
  {
    path: 'teams',
    name: 'teams' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Teams',
      icon: 'team'
    },
    redirect: () => getFirstPossibleRoute(teamsChildrenRoutes),
    children: teamsChildrenRoutes
  }
] satisfies Route[]
