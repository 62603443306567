import type { MaybeRef } from '@vueuse/core'

import { httpService } from '@/api/http.service'
import { useFetch } from '@/composables/useFetch'

export function useUserInfo(enabled: MaybeRef<boolean> = true) {
  return useFetch(['userInfo'], () => httpService.users.usersMeRetrieve(), {
    keepPreviousData: true,
    enabled
  })
}
