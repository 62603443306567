<script lang="tsx">
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      version: null as string | null,
      unableToFetch: false,
      updateStatusInterval: null as number | null,
      alertDisplayed: false as string | false
    }
  },
  computed: {
    baseUrl(): string {
      return `${location.protocol}//${location.hostname}${
        location.port ? ':' + location.port : ''
      }`
    }
  },
  beforeUnmount() {
    if (this.updateStatusInterval) {
      clearInterval(this.updateStatusInterval)
      this.updateStatusInterval = null
    }
  },
  created() {
    this.fetchInitialVersion()
  },
  methods: {
    getUrl() {
      return `${this.baseUrl}/version.txt?v=${Date.now()}`
    },

    fetchInitialVersion() {
      axios
        .get(this.getUrl())
        .then(res => {
          // In dev, the response is html, so we ignore it
          if (res.headers['content-type'] === 'text/html') return

          this.version = res.data
          this.setTimer()
        })
        .catch(() => {
          this.unableToFetch = true
        })
    },
    setTimer() {
      if (!this.updateStatusInterval) {
        this.updateStatusInterval = window.setInterval(() => {
          this.checkVersion()
        }, 60000)
      }
    },
    showAlert() {
      const key = `open${Date.now()}`
      this.alertDisplayed = key

      this.$notification.open({
        message: 'New version available',
        duration: 0,
        description:
          "There's new version available. Please save your work and refresh website, or click Refresh",
        btn: () => (
          <a-button type="primary" size="small" onClick={this.refreshBrowser}>
            Refresh
          </a-button>
        ),
        key,
        onClose: () => {
          this.alertDisplayed = false
        }
      })
    },
    refreshBrowser() {
      // @ts-expect-error param is only supported in firefox
      window.location.reload(true) // true to fetch without cache
    },
    async checkVersion() {
      try {
        const response = await axios.get(this.getUrl())
        const newVersion = response.data
        const showAlert = this.version && newVersion !== this.version

        if (!this.alertDisplayed && showAlert) {
          this.showAlert()
        }
      } catch {
        // in dev do nothing
      }
    }
  }
})
</script>

<style scoped>
.new-version-alert {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 123;
}
</style>
