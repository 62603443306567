import { getFirstPossibleRoute } from '@/router/utils/redirectHelpers'
import { type Route } from '@/types/route'

const timetablePreviewChildrenRoutes = [
  {
    path: '',
    name: 'timetable-preview-view' as const,
    component: () => import('@/views/TeacherPanel/TimetablePreview.vue'),
    meta: {
      title: 'Timetable Preview'
    }
  }
] satisfies Route[]

export const timetablePreviewRoutes = [
  {
    path: 'timetable-preview',
    name: 'timetable-preview' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Timetable Preview',
      icon: 'calendar',
      permissions: [
        'draft_activities.view_draftactivity',
        'rota_activities.view_draftrotaactivity'
      ]
    },
    redirect: () => getFirstPossibleRoute(timetablePreviewChildrenRoutes),
    children: timetablePreviewChildrenRoutes
  }
] satisfies Route[]
