import Vue from 'vue'
import type { VNode } from 'vue/types/umd'

type NotificationOptions = {
  duration?: number
  class?: string
  key?: string
  icon?: () => VNode
  description?: string | (() => VNode)
  onClose?: () => void
}

import { DEFAULT_ERROR_MESSAGE } from '@/constants/error-messages'

export function errorNotification(
  errorText?: string | (() => VNode),
  options?: NotificationOptions
) {
  Vue.prototype.$notification.error({
    message: errorText || DEFAULT_ERROR_MESSAGE,
    ...options
  })
}

export function successNotification(
  successText: string | (() => VNode),
  options?: NotificationOptions
) {
  Vue.prototype.$notification.success({
    message: successText,
    ...options
  })
}

export function infoNotification(
  successText: string | (() => VNode),
  options?: NotificationOptions
) {
  Vue.prototype.$notification.info({
    message: successText,
    ...options
  })
}

export function warningNotification(
  warningText: string,
  options?: NotificationOptions
) {
  Vue.prototype.$notification.warning({
    message: warningText,
    ...options
  })
}
