import RuleRic9 from './RIC_9'

const entries = Object.entries(RuleRic9.choices)

const updatedEntries = entries.map(([key, value]) =>
  key === 'IXI200'
    ? ['IXI300', { ...value, codeStartsWith: 'IXI3' }]
    : [key, value]
)

const choices = Object.fromEntries(updatedEntries)

export default {
  ...RuleRic9,
  gradeLevelId: 10,
  choices
}
