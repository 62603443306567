import { getFirstPossibleRoute } from '@/router/utils/redirectHelpers'
import { type Route } from '@/types/route'

const healthSickReportDetailsRoutes = [
  {
    path: 'details',
    name: 'sick-report-details' as const,
    component: () => import('@/views/SickReportDetails/SickReportDetails.vue'),
    meta: {
      title: 'Sick report details',
      permissions: ['sick_reports.view_sickreport']
    }
  },
  {
    path: 'absences',
    name: 'sick-report-absences' as const,
    component: () =>
      import('@/views/SickReportDetails/SickReportStudentsAbsences/index.vue'),
    meta: {
      title: "Student's absences",
      permissions: ['sick_reports.view_sickreport']
    }
  },
  {
    path: 'check-history',
    name: 'sick-report-health-check-history' as const,
    component: () =>
      import(
        '@/views/SickReportDetails/SickReportHealthCheckHistory/index.vue'
      ),
    meta: {
      title: 'Health check history',
      permissions: ['health_checks.view_healthcheck']
    }
  },
  {
    path: 'comments',
    name: 'sick-report-comments' as const,
    component: () =>
      import('@/views/SickReportDetails/SickReportComments/index.vue'),
    meta: {
      title: 'Comments',
      permissions: ['sick_reports.view_sickreportcomment']
    }
  }
] satisfies Route[]

const healthSickReportsRoutes = [
  {
    path: ':id',
    name: 'health-sick-report-details-tabs' as const,
    component: () => import('@/views/SickReportDetails/index.vue'),
    props: {
      routeList: healthSickReportDetailsRoutes
    },
    redirect: () => getFirstPossibleRoute(healthSickReportDetailsRoutes),
    children: healthSickReportDetailsRoutes
  },
  {
    path: 'create',
    component: () => import('@/views/SickReportCreate/index.vue'),
    name: 'sick-report-create' as const,
    meta: {
      permissions: ['sick_reports.add_sickreport']
    }
  }
] satisfies Route[]

const healthTabRoutes = [
  {
    path: 'sick-reports',
    name: 'sick-reports' as const,
    component: () => import('@/views/SickReportList/SickReportListView.vue'),
    meta: {
      label: 'Sick reports',
      icon: 'profile',
      permissions: ['sick_reports.list_sickreport']
    }
  },
  {
    path: 'medicine-plans',
    name: 'medicine-plan-list' as const,
    component: () =>
      import('@/views/Health/MedicineInPlanList/MedicineInPlanListView.vue'),
    meta: {
      label: 'Medicine plans',
      icon: 'medicine-box',
      permissions: ['medicine_plan.view_medicineinplan']
    }
  },
  {
    path: 'health-checks',
    name: 'health-checks-list' as const,
    component: () =>
      import('@/views/Health/HealthCheckList/HealthCheckListView.vue'),
    meta: {
      label: 'Health checks',
      icon: 'schedule',
      permissions: ['health_checks.view_healthcheck']
    }
  }
] satisfies Route[]

const healthNotTabRoutes = [
  {
    path: '',
    name: 'health-tab' as const,
    component: () => import('@/components/base/BaseLayout.vue'),
    props: {
      routes: healthTabRoutes
    },
    redirect: () => getFirstPossibleRoute(healthTabRoutes),
    children: healthTabRoutes
  },
  {
    path: 'sick-reports',
    name: 'sick-reports-tabs' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    redirect: () => getFirstPossibleRoute(healthSickReportsRoutes),
    children: healthSickReportsRoutes
  }
] satisfies Route[]

export const healthRoutes = [
  {
    path: 'health',
    name: 'health' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      icon: 'medicine-box',
      title: 'Health',
      permissions: ['sick_reports.list_sickreport']
    },
    redirect: () => getFirstPossibleRoute(healthNotTabRoutes),
    children: healthNotTabRoutes
  }
] satisfies Route[]
