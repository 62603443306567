import RuleRic9 from './RIC_9'

const entries = Object.entries(RuleRic9.choices)

const updatedEntries = entries.filter(([key]) => key !== 'IXI200')
const choices = Object.fromEntries(updatedEntries)

export default {
  ...RuleRic9,
  gradeLevelId: 11,
  choices
}
