import { type Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/redirectHelpers'

const coursesOverviewTabRoutes = [
  {
    path: 'students',
    name: 'course-details-students' as const,
    component: () => import('@/views/CourseDetails/Students/index.vue'),
    meta: {
      title: 'Students',
      icon: 'user'
    }
  },
  {
    path: 'details',
    name: 'course-details' as const,
    component: () =>
      import('@/views/CourseDetails/Details/BasicInformation.vue'),
    meta: {
      title: 'Basic information',
      icon: 'book'
    }
  }
] satisfies Route[]

const coursesOverviewNotTabRoutes = [
  {
    path: '',
    name: 'course-list' as const,
    component: () => import('@/views/CourseList/CourseListView.vue'),
    meta: {
      title: 'Courses'
    }
  },
  {
    path: ':courseId',
    name: 'course-overview-tab' as const,
    component: () => import('@/components/base/BaseLayout.vue'),
    props: {
      routes: coursesOverviewTabRoutes
    },
    redirect: () => getFirstPossibleRoute(coursesOverviewTabRoutes),
    children: coursesOverviewTabRoutes
  }
] satisfies Route[]

export const coursesOverviewRoutes = [
  {
    path: 'courses',
    name: 'courses' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      getTitle: safeMode => (safeMode ? 'My courses' : 'Courses overview'),
      icon: 'laptop',
      permissions: ['courses.view_course']
    },
    redirect: () => getFirstPossibleRoute(coursesOverviewNotTabRoutes),
    children: coursesOverviewNotTabRoutes
  }
] satisfies Route[]
