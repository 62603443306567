import { type Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/redirectHelpers'

const diningHallTabRoutes = [
  {
    path: 'menu-planner',
    name: 'menu-planner' as const,
    meta: {
      title: 'Menu planner',
      permissions: ['menu.view_daymenu']
    },
    component: () => import('@/views/DiningHall/MenuPlanner/MenuPlanner.vue')
  },
  {
    path: 'meal-attendance',
    name: 'meal-attendance' as const,
    meta: {
      title: 'Meal attendance',
      permissions: ['permissions.view_mealattendance']
    },
    component: () =>
      import('@/views/DiningHall/MealAttendance/MealAttendance.vue')
  }
] satisfies Route[]

export const diningHallRoutes = [
  {
    path: 'dining-hall',
    name: 'dining-hall' as const,
    component: () => import('@/components/base/BaseLayout.vue'),
    props: {
      routes: diningHallTabRoutes
    },
    meta: {
      title: 'Dining hall',
      icon: 'coffee'
    },
    redirect: () => getFirstPossibleRoute(diningHallTabRoutes),
    children: diningHallTabRoutes
  }
] satisfies Route[]
