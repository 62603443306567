import { keyBy } from 'lodash'

export default {
  gradeLevelId: 9,
  aimName: 'RIC',
  minLessons: 30,
  maxLessons: 36,
  choices: {
    ENG: {
      choice_type: 'default',
      basket: 'English',
      numberOfLessons: 5,
      level: 'HSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'ENG',
      approvalRequired: false,
      courseRequestAvailable: true,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    ELA: {
      choice_type: 'default',
      basket: 'English I',
      numberOfLessons: 5,
      level: 'HSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      subject: ['ELA'],
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false,
      additionalCourses: ['ENG350', 'ENG420']
    },
    MAT: {
      choice_type: 'default',
      basket: 'Mathematics',
      numberOfLessons: 5,
      level: 'HSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'MAT',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    SCI: {
      choice_type: 'default',
      basket: 'Science',
      numberOfLessons: 5,
      level: 'HSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'SCI',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    SSD: {
      choice_type: 'default',
      basket: 'Social Science',
      numberOfLessons: 5,
      level: 'HSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'SSD',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    MLA: {
      choice_type: 'default',
      basket: 'Modern Language',
      numberOfLessons: 5,
      level: 'HSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'MLA',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'MLA Lab': {
      choice_type: 'default',
      basket: 'Modern Language Lab',
      numberOfLessons: 5,
      level: 'HSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'MLA',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'MLA Workshop or CEFR': {
      choice_type: 'default',
      basket: 'Modern Language Workshop',
      numberOfLessons: 5,
      level: 'HSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'MLA',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    IXI200: {
      choice_type: 'default',
      basket: 'HumanIXI',
      numberOfLessons: 2,
      level: 'HSL',
      types: ['RIC'],
      subject: ['IXI'],
      codeStartsWith: 'IXI2',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false,
      availableAfter: '2024/25'
    },
    Free: {
      choice_type: 'free_choice',
      basket: 'Free Choice',
      numberOfLessons: 5,
      level: 'HSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    'Add-on': {
      choice_type: 'addon',
      basket: 'Add-on',
      numberOfLessons: 2,
      level: 'HSL',
      types: ['RIC'],
      group: 'ADD',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    }
  },
  allowPrivateFormset: true,
  privateFormsetChoice: {
    basket: 'Optional',
    optional: true,
    forcePrivate: true
  },
  rules: [
    ({ self, template, form }) => {
      const formMap = keyBy(form.baskets, 'key')
      const courseMap = self.$store.state.idpRules.coursesMap

      if (
        formMap['Free']?.courses.filter(
          c => courseMap[c.course]?.subject === 'SCI'
        ).length
      ) {
        template.choices['ELA'].swapAvailable = false
        formMap['ELA'].courses.forEach(c => {
          if (c.swapped) {
            c.swapped = false
            self.$notification.info({ message: 'SWAP deselected in ELA' })
          }
        })
        template.choices['ELA'].subject = ['ELT', 'ELA']
      } else {
        template.choices['ELA'].subject = ['ELA']
        template.choices['ELA'].swapAvailable = true
      }
      if (
        formMap['Free']?.courses.filter(
          c => courseMap[c.course]?.subject === 'SSD'
        ).length
      ) {
        template.choices['ELA'].swapAvailable = true
      }
    }
  ]
}
