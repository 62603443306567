import { type Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/redirectHelpers'

const myTimetableChildRoutes = [
  {
    path: '',
    name: 'my-timetable-view' as const,
    component: () => import('@/views/Timetable/MyTimetableView.vue'),
    meta: {
      title: 'My Timetable'
    }
  },
  {
    path: ':lessonId',
    name: 'my-timetable-activity' as const,
    component: () => import('@/views/TeacherPanel/TimetableDetails.vue'),
    meta: {
      title: 'My Timetable',
      permissions: ['activities.view_lesson']
    }
  }
] satisfies Route[]

export const myTimetableRoutes = [
  {
    path: 'my-timetable',
    name: 'my-timetable' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'My timetable',
      icon: 'calendar',
      permissions: ['permissions.view_my_timetable']
    },
    redirect: () => getFirstPossibleRoute(myTimetableChildRoutes),
    children: myTimetableChildRoutes
  }
] satisfies Route[]
