export function assertIsDefined<T>(
  value: T,
  message?: string
): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(message || `${value} is not defined`)
  }
}

export function assert(condition: boolean, message?: string) {
  if (!condition) throw new Error(message || 'Assertion error')
}
