import { type Route } from '@/types/route'

import { filterRoutesWithPermissions } from './filterRoutesWithPermissions'

export const getFirstPossibleRoute = (routes: Route[]) =>
  filterRoutesWithPermissions(routes)[0]

export const getDeepRedirectRoute = (route: Route): Route => {
  if (route?.redirect && route?.children)
    return getDeepRedirectRoute(getFirstPossibleRoute(route.children))
  return route
}
