<template>
  <a-alert
    class="base-alert"
    :class="`base-alert--${type}`"
    :message="message"
    :type="type"
    :show-icon="!hideIcon"
    :description="description"
  >
    <template v-if="spinning" #icon>
      <a-spin size="small" class="spin-icon" />
    </template>

    <template #message>
      <slot name="message" />
    </template>

    <template v-if="slots.description || description" #description>
      <slot name="description" />
    </template>
  </a-alert>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

export type AlertType = 'success' | 'info' | 'warning' | 'error'

withDefaults(
  defineProps<{
    description?: string
    message?: string
    hideIcon?: boolean
    spinning?: boolean
    type?: AlertType
  }>(),
  { type: 'error', hideIcon: false, spinning: false }
)

const slots = useSlots()
</script>

<style lang="scss">
.base-alert {
  padding: 8px 16px 8px 44px;
  line-height: 22px;

  &-icon {
    font-size: 14px;
  }

  @mixin base-alert-types($name, $background, $border) {
    &--#{$name} {
      background-color: $background;
      border: 1px solid $border;

      :deep(.ant-alert-icon) {
        color: $border;
      }
    }
  }

  @include base-alert-types(error, $danger-1, $danger-3);

  @include base-alert-types(info, $info-1, $info-3);

  @include base-alert-types(success, $success-1, $success-3);

  @include base-alert-types(warning, $warning-1, $warning-3);
}

.ant-alert-with-description,
.ant-alert {
  .ant-alert-message {
    color: $neutral-7;
    margin-bottom: 0;
  }
}

.ant-alert-with-description {
  padding: 8px 16px 8px 52px;
  color: $neutral-7;

  .anticon {
    font-size: 21px;
    top: 10px;
    left: 17px;
  }

  .ant-alert-message {
    line-height: 24px;
  }
}

.ant-alert {
  .ant-spin-dot-item {
    background-color: $info;
  }

  .spin-icon {
    margin-top: -5px;
  }
}
</style>
