import { intersection, keyBy } from 'lodash'

import { IdpPlan } from '@/api/index.js'
import A1 from '@/store/rules/A1'
import A10 from '@/store/rules/A10'
import A11 from '@/store/rules/A11'
import A12 from '@/store/rules/A12'
import A13 from '@/store/rules/A13'
import A2 from '@/store/rules/A2'
import A3 from '@/store/rules/A3'
import A4 from '@/store/rules/A4'
import A5 from '@/store/rules/A5'
import A6 from '@/store/rules/A6'
import A7 from '@/store/rules/A7'
import A8 from '@/store/rules/A8'
import A9 from '@/store/rules/A9'
import IB_11 from '@/store/rules/IB_11'
import IB_12 from '@/store/rules/IB_12'
import IB_13 from '@/store/rules/IB_13'
import RIC_1 from '@/store/rules/RIC_1'
import RIC_10 from '@/store/rules/RIC_10'
import RIC_11 from '@/store/rules/RIC_11'
import RIC_12 from '@/store/rules/RIC_12'
import RIC_13 from '@/store/rules/RIC_13'
import RIC_14 from '@/store/rules/RIC_14'
import RIC_15 from '@/store/rules/RIC_15'
import RIC_2 from '@/store/rules/RIC_2'
import RIC_3 from '@/store/rules/RIC_3'
import RIC_4 from '@/store/rules/RIC_4'
import RIC_5 from '@/store/rules/RIC_5'
import RIC_6 from '@/store/rules/RIC_6'
import RIC_7 from '@/store/rules/RIC_7'
import RIC_8 from '@/store/rules/RIC_8'
import RIC_9 from '@/store/rules/RIC_9'
import RIC_AP_1 from '@/store/rules/RIC_AP_1'
import RIC_AP_10 from '@/store/rules/RIC_AP_10'
import RIC_AP_11 from '@/store/rules/RIC_AP_11'
import RIC_AP_12 from '@/store/rules/RIC_AP_12'
import RIC_AP_13 from '@/store/rules/RIC_AP_13'
import RIC_AP_2 from '@/store/rules/RIC_AP_2'
import RIC_AP_3 from '@/store/rules/RIC_AP_3'
import RIC_AP_4 from '@/store/rules/RIC_AP_4'
import RIC_AP_5 from '@/store/rules/RIC_AP_5'
import RIC_AP_6 from '@/store/rules/RIC_AP_6'
import RIC_AP_7 from '@/store/rules/RIC_AP_7'
import RIC_AP_8 from '@/store/rules/RIC_AP_8'
import RIC_AP_9 from '@/store/rules/RIC_AP_9'

export default {
  namespaced: true,
  state: () => ({
    courses: [],
    loadingCourses: true,
    numberOfLessonsChoices: Array.from({ length: 25 }, (_, i) => ({
      label: `${i + 1}`,
      value: i + 1
    })),
    templates: [
      A1,
      A2,
      A3,
      A4,
      A5,
      A6,
      A7,
      A8,
      A9,
      A10,
      A11,
      A12,
      A13,
      RIC_1,
      RIC_2,
      RIC_3,
      RIC_4,
      RIC_5,
      RIC_6,
      RIC_7,
      RIC_8,
      RIC_9,
      RIC_10,
      RIC_11,
      RIC_12,
      RIC_13,
      RIC_14,
      RIC_15,
      IB_11,
      IB_12,
      IB_13,
      RIC_AP_1,
      RIC_AP_2,
      RIC_AP_3,
      RIC_AP_4,
      RIC_AP_5,
      RIC_AP_6,
      RIC_AP_7,
      RIC_AP_8,
      RIC_AP_9,
      RIC_AP_10,
      RIC_AP_11,
      RIC_AP_12,
      RIC_AP_13
    ],
    coursesMap: {},
    statuses: [],
    suggestions: {
      completed_courses: [],
      suggestions_per_basket: {}
    }
  }),
  mutations: {
    setCourses(state, payload) {
      state.courses = payload
      state.coursesMap = keyBy(payload, 'value')
    },
    setStatuses(state, payload) {
      state.statuses = payload
    },
    setLoadingCourses(state, payload) {
      state.loadingCourses = payload
    },
    clearSuggestions(state) {
      state.suggestions = {
        completed_courses: [],
        suggestions_per_basket: {}
      }
    },
    setSuggestions(state, payload) {
      state.suggestions = payload
    }
  },
  actions: {
    fetchStatuses({ commit }) {
      IdpPlan.statuses().then(res => {
        commit('setStatuses', res.data)
      })
    },
    fetchCourses({ commit }) {
      commit('setLoadingCourses', true)
      IdpPlan.courses()
        .then(res => {
          commit(
            'setCourses',
            res.data.map(course => ({
              ...course,
              value: course.id,
              label: `${course.code} - ${course.name}`
            }))
          )
        })
        .finally(() => {
          commit('setLoadingCourses', false)
        })
    },
    fetchSuggestions({ commit }, params) {
      IdpPlan.suggestions(params)
        .then(res => {
          commit('setSuggestions', res.data)
        })
        .catch(() => {
          commit('clearSuggestions')
        })
    }
  },
  getters: {
    getSuggestionsForCourseId: state => courseId => {
      const course = state.coursesMap[courseId]
      if (!course) {
        return
      }
      const nextCourseIds = course.next_course_ids
      return nextCourseIds.map(id => state.coursesMap[id]).filter(Boolean)
    },
    getCourses:
      state =>
      ({
        additionalCourses,
        department,
        types,
        subject,
        family,
        group,
        level,
        isTalentAndEnrichment,
        codeStartsWith
      }) => {
        const courses = state.courses.filter(course => {
          if (additionalCourses?.includes(course.code)) return true

          if (codeStartsWith) {
            if (!course.code.startsWith(codeStartsWith)) return false
          }
          if (department) {
            if (!course.departments.includes(department)) return false
          }
          if (subject) {
            if (Array.isArray(subject)) {
              if (intersection([course.subject], subject).length === 0)
                return false
            } else {
              if (course.subject !== subject) return false
            }
          }
          if (group) {
            if (course.group !== group) return false
          }
          if (level) {
            if (!course.levels.includes(level)) return false
          }
          if (isTalentAndEnrichment) {
            if (!course.is_talent_and_enrichment) return false
          }
          if (family) {
            if (!course.families.includes(family)) return false
          }
          if (types && types.length) {
            if (intersection(course.types, types).length === 0) return false
          }
          return true
        })
        return courses
      }
  }
}
