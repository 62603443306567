import { useRoute, useRouter } from 'vue-router/composables'

import type { RosenAppRoute, RosenLocation } from '@/types/route'

export const useRosenRoute = () => {
  const route = useRoute() as RosenAppRoute
  const router = useRouter()

  const goToRosenRoute = (
    location: RosenLocation,
    method: 'replace' | 'push' = 'replace'
  ) => {
    router[method](location)
  }

  const isActiveLink = (name: string) =>
    route.matched.some(location => location.name === name)

  return { route, goToRosenRoute, isActiveLink }
}
