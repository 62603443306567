import { isEmpty, keyBy } from 'lodash'

import store from '@/store'

export function countLessonNumbers(form) {
  // returned format
  // [
  //   { name: 'Q1', lessons: 40 },
  //   { name: 'Q2', lessons: 40 },
  // ]

  const quarters = store.state.quarters
    .filter(quarter => quarter.school_year === form.year)
    .map(quarter => ({
      name: quarter.short_name,
      id: quarter.id,
      lessons: 0
    }))

  const quarterLessons = keyBy(quarters, 'id')
  const baskets = form.baskets.filter(basket => !basket.is_ignored)

  baskets.forEach(basket => {
    basket.courses.forEach(course => {
      course.quarters.forEach(sectionEnrollment => {
        const lessons = sectionEnrollment.number_of_lessons
        if (
          lessons &&
          quarterLessons[sectionEnrollment.quarter] &&
          !isDroppedSectionEnrollment(sectionEnrollment)
        ) {
          quarterLessons[sectionEnrollment.quarter].lessons += lessons
        }
      })
    })
  })
  return Object.values(quarterLessons)
}

export function isDroppedSectionEnrollment(sectionEnrollment) {
  return !!(
    sectionEnrollment?.course_drop_data?.section ||
    sectionEnrollment?.course_drop
  )
}

export function getBasketsWithoutDroppedCourses(baskets) {
  return baskets.reduce((basketsAcc, basket) => {
    const courses = basket?.courses.reduce((coursesAcc, course) => {
      const sectionEnrollments = course?.quarters.filter(
        sectionEnrollment => !isDroppedSectionEnrollment(sectionEnrollment)
      )
      return sectionEnrollments?.length
        ? [...coursesAcc, { ...course, sectionEnrollments }]
        : sectionEnrollments
    }, [])

    return courses?.length
      ? [...basketsAcc, { ...basket, courses }]
      : basketsAcc
  }, [])
}

export function removeIdFromPayload(form) {
  // removes id & idp_plan fields from payload
  // used to create new idp from from the existing one
  // return status new or draft!
  return {
    student: form.student,
    grade_level: form.grade_level,
    aim: form.aim,
    year: form.year,
    warnings: form.warnings.map(warning => ({
      note: warning.note,
      accepted_by_parent: warning.accepted_by_parent,
      content: warning.content
    })),
    exams: form.exams.map(exam => ({
      name: exam.name,
      score: exam.score
    })),
    baskets: form.baskets.map(basket => ({
      name: basket.name,
      is_ignored: basket.is_ignored,
      key: basket.key,
      choice_type: basket.choice_type,
      include_extra_basket: basket.include_extra_basket,
      courses: basket.courses.map(course => ({
        requested_course: course.requested_course,
        course: course.course,
        course_name: course.course_name,
        course_code: course.course_code,
        apply_for_all_quarters: course.apply_for_all_quarters,
        swapped: course.swapped,
        swap_department: course.swap_department,
        department: course.swap_department,
        quarters: course.quarters.map(quarter => ({
          course_drop_student_idp_plan_id:
            quarter.course_drop_student_idp_plan_id,
          course_drop: quarter?.course_drop,
          course_drop_data: quarter?.course_drop_data,
          number_of_lessons: quarter.number_of_lessons,
          is_private: quarter.is_private,
          quarter: quarter.quarter,
          section: quarter.section
        }))
      }))
    }))
  }
}

function getMapFromIdpPayload(payload) {
  // { 'Choice 1': { 1: { course: { name: 'Math' }, section: 9 }, key: 'Choice 1' } }

  const courseMap = store.state.idpRules.coursesMap
  const basketQuarterMap = {}

  if (isEmpty(payload)) {
    return {}
  }

  payload.baskets.forEach(basket => {
    basketQuarterMap[basket.key] = {
      key: basket.key,
      name: basket.name
    }
    basket.courses.forEach(course => {
      course.quarters.forEach(quarter => {
        basketQuarterMap[basket.key][quarter.quarter] = {
          course: courseMap[course.course],
          section: quarter.section,
          number_of_lessons: quarter.number_of_lessons
        }
      })
    })
  })
  return basketQuarterMap
}

export function getOptionsForIDPPayloads({
  idpPlanPayload,
  sourceIdpPlanPayload,
  activeIdpPlanPayload,
  quarterId
}) {
  const idpMap = getMapFromIdpPayload(idpPlanPayload)
  const sourceIdpMap = getMapFromIdpPayload(sourceIdpPlanPayload)
  const activeIdpMap = getMapFromIdpPayload(activeIdpPlanPayload)

  if (isEmpty(idpMap) || isEmpty(sourceIdpMap)) {
    return {}
  }

  const optionsForBaskets = {}
  Object.entries(idpMap).forEach(([basketKey, basket]) => {
    if (!basket[quarterId]) {
      return {}
    }
    const options = [
      {
        type: 'idp',
        section: basket[quarterId].section,
        course: basket[quarterId].course?.id,
        number_of_lessons: basket[quarterId].number_of_lessons,
        selected: true,
        name: 'Changes request'
      }
    ]

    const activeBasket =
      activeIdpMap[basketKey] && activeIdpMap[basketKey][quarterId]

    if (activeBasket) {
      options.push({
        type: 'active_idp',
        section: activeBasket.section,
        course: activeBasket.course?.id,
        number_of_lessons: activeBasket.number_of_lessons,
        selected: false,
        name: 'Active IDP Plan'
      })
    }

    const oldBasket =
      sourceIdpMap[basketKey] && sourceIdpMap[basketKey][quarterId]

    if (oldBasket) {
      options.push(
        {
          type: 'timetable',
          section: oldBasket.section,
          course: oldBasket.course?.id,
          selected: false,
          number_of_lessons: oldBasket.number_of_lessons,
          name: 'Timetable'
        },
        {
          type: 'custom',
          section: null,
          course: null,
          number_of_lessons: basket[quarterId].number_of_lessons,
          selected: false,
          name: 'Custom (read only)'
        }
      )
    }

    optionsForBaskets[basketKey] = {
      options,
      key: basket.key,
      name: basket.name
    }
  })

  return optionsForBaskets
}

function appendExamExtraBasketsToIdpTemplate({ plan, examName, courseTypes }) {
  // add extrabasket checkbox to every default choice basket
  const choices = plan.choices
  const newChoices = {}

  Object.entries(choices).forEach(([basketKey, basket]) => {
    const examBasketKey = `${basketKey} ${examName} Exam`
    newChoices[basketKey] = {
      ...basket,
      extraBasket: {
        label: `Does student require ${examName} exam preparation?`,
        key: examBasketKey,
        condition(basketPayload) {
          const courseMap = store.state.idpRules.coursesMap
          const courseWithSpecifiedType = basketPayload.courses.find(
            courseInBasket => {
              if (courseInBasket.course) {
                const course = courseMap[courseInBasket.course]
                return course?.types.some(type => courseTypes.includes(type))
              } else {
                return false
              }
            }
          )

          return !!courseWithSpecifiedType
        }
      }
    }
    newChoices[examBasketKey] = {
      basket: basket.basket,
      types: courseTypes,
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false,
      choice_type: 'exam',
      condition(idpPlanForm) {
        const choice = idpPlanForm.baskets.find(({ key }) => key === basketKey)
        return choice && choice.include_extra_basket
      }
    }
  })
  return {
    ...plan,
    choices: newChoices
  }
}

export function appendAPExamTemplate(plan) {
  return appendExamExtraBasketsToIdpTemplate({
    plan,
    examName: 'AP',
    courseTypes: ['AP1', 'AP2', 'AP', 'AP3']
  })
}

export function appendALExamTemplate(plan) {
  const choiceTypes = ['AL1', 'AL2', 'AL3']
  const newPlan = appendExamExtraBasketsToIdpTemplate({
    plan,
    examName: 'AS/A2',
    courseTypes: choiceTypes
  })
  Object.values(newPlan.choices).forEach(choice => {
    choiceTypes.forEach(type => {
      if (!choice.types.includes(type)) {
        choice.types.push(type)
      }
    })
  })

  return newPlan
}

export function getChoiceTypeForKey(key) {
  // Handle Free Choice*, Add-on, Private Course
  const lowerKey = key.toLowerCase()

  if (lowerKey.startsWith('private')) {
    return 'private'
  } else if (lowerKey.startsWith('add')) {
    return 'addon'
  } else if (lowerKey.startsWith('free')) {
    return 'free_choice'
  } else if (lowerKey.includes('exam')) {
    return 'exam'
  } else {
    return 'default'
  }
}

export function getTemplateForBasket(template, basket) {
  const isPrivateItem = basket.choice_type === 'private'
  return isPrivateItem
    ? template.privateFormsetChoice
    : template?.choices[basket.key]
}

export function showBasket({ basket, template, form }) {
  const basketTemplate = getTemplateForBasket(template, basket)

  if (!basketTemplate) {
    return false
  }

  return basketTemplate.condition ? basketTemplate.condition(form) : true
}

export function appendHigherLevel(plan) {
  const choices = plan.choices
  const newChoices = {}

  Object.entries(choices).forEach(([basketKey, basket]) => {
    const examBasketKey = `${basketKey} Higher Level`

    newChoices[basketKey] = {
      ...basket,
      extraBasket: {
        label: 'Higher Level',
        key: examBasketKey
      }
    }

    const higherLevelBasket = {
      ...basket,
      level: 'HSL',
      choice_type: 'higher_level',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false,
      basket: `${basket.basket} Higher Level`,
      condition(idpPlanForm) {
        const choice = idpPlanForm.baskets.find(({ key }) => key === basketKey)
        return choice && choice.include_extra_basket
      }
    }
    newChoices[examBasketKey] = higherLevelBasket
  })
  return {
    ...plan,
    choices: newChoices
  }
}

/**
 * Checks if basket is available in given year.
 * @param {string} basketAvailability - Basket availability, eg. 2024/25.
 * @param {string} year - Year short name, eg. 2023/24.
 * @returns {boolean} - Is available.
 */
export function isBasketAvailableInYear(basketAvailability, year) {
  if (!basketAvailability) return true

  const availabilityYear = Number(basketAvailability.split('/')[0])
  const yearToCheck = Number(year.split('/')[0])

  return availabilityYear <= yearToCheck
}
