import { onMounted, onUnmounted } from 'vue'

export const useEvent = ({
  name,
  onEvent
}: {
  name: string
  onEvent?: () => void
}) => {
  const dispatchEvent = () => window.dispatchEvent(new Event(name))

  onMounted(() => {
    if (!onEvent) return
    window.addEventListener(name, onEvent)
  })

  onUnmounted(() => {
    if (!onEvent) return
    window.removeEventListener(name, onEvent)
  })

  return {
    dispatchEvent
  }
}
