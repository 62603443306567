import { getFirstPossibleRoute } from '@/router/utils/redirectHelpers'
import { type Route } from '@/types/route'

const alertsChildrenRoutes = [
  {
    path: '',
    name: 'alerts-list' as const,
    component: () => import('@/views/Alerts/AlertsView.vue'),
    meta: {
      title: 'Alerts'
    }
  }
] satisfies Route[]

export const alertsRoutes = [
  {
    path: 'alerts',
    name: 'alerts' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Alerts',
      icon: 'rise',
      permissions: ['attendances.view_academicattendancealert']
    },
    redirect: () => getFirstPossibleRoute(alertsChildrenRoutes),
    children: alertsChildrenRoutes
  }
] satisfies Route[]
