import { getFirstPossibleRoute } from '@/router/utils/redirectHelpers'
import { type Route } from '@/types/route'

const disciplineTabRoutes = [
  {
    path: 'student-category-reports',
    name: 'student-category-reports' as const,
    component: () =>
      import(
        '@/views/Discipline/StudentCategoryReport/StudentCategoryReportView.vue'
      ),
    meta: {
      icon: 'calendar',
      title: 'Category reports',
      permissions: ['discipline.view_studentcategoryreport']
    }
  },
  {
    path: 'merits',
    name: 'merits-and-demerits-list' as const,
    component: () =>
      import('@/views/Discipline/MeritsDemerits/MeritsDemeritsView.vue'),
    meta: {
      icon: 'rise',
      title: 'Merits & Demerits',
      permissions: ['merits.view_merit']
    }
  },
  {
    path: 'merits-exchange',
    name: 'merits-exchange-list' as const,
    component: () =>
      import('@/views/Discipline/MeritsExchange/MeritsExchangeView.vue'),
    meta: {
      icon: 'gift',
      title: 'Merits exchange',
      permissions: ['merits_exchange.view_meritreward']
    }
  },
  {
    path: 'boarding-comments',
    name: 'boarding-comment-list' as const,
    component: () =>
      import('@/views/Discipline/BoardingCommentList/BoardingCommentsView.vue'),
    meta: {
      icon: 'message',
      title: 'Boarding Comments',
      permissions: ['boarding_comments.view_boardingcomment']
    }
  },
  {
    path: 'recovery',
    name: 'recovery-list' as const,
    component: () => import('@/views/Discipline/Recovery/RecoveryView.vue'),
    meta: {
      icon: 'hourglass',
      title: 'Absence & lunchtime recovery',
      permissions: ['recovery.view_recovery']
    }
  }
] satisfies Route[]

const disciplineNotTabRoutes = [
  {
    path: '',
    name: 'discipline-tab' as const,
    component: () => import('@/components/base/BaseLayout.vue'),
    props: {
      routes: disciplineTabRoutes
    },
    meta: {
      title: 'Discipline'
    },
    redirect: () => getFirstPossibleRoute(disciplineTabRoutes),
    children: disciplineTabRoutes
  },
  {
    path: 'student-category-reports/:reportId',
    name: 'student-category-report-details' as const,
    component: () =>
      import(
        '@/views/Discipline/StudentCategoryReport/CategoryReportDetailsView.vue'
      ),
    meta: {
      title: 'Student category report detail',
      permissions: ['discipline.view_studentcategoryreport']
    }
  },
  {
    path: 'recovery/:recoveryId',
    name: 'recovery-edit' as const,
    component: () => import('@/views/Discipline/Recovery/RecoveryEditView.vue'),
    meta: {
      title: 'Edit Recovery',
      permissions: ['recovery.change_recovery']
    }
  }
] satisfies Route[]

export const disciplineRoutes = [
  {
    path: 'discipline',
    name: 'discipline' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Discipline',
      icon: 'safety',
      label: 'Discipline',
      permissions: ['discipline.view_studentcategoryreport']
    },
    redirect: () => getFirstPossibleRoute(disciplineNotTabRoutes),
    children: disciplineNotTabRoutes
  }
] satisfies Route[]
