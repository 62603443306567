import { type Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/redirectHelpers'

const infoboardsTabRoutes = [
  {
    path: 'staff-boards',
    name: 'infoboards-staff-boards' as const,
    component: () => import('@/views/Infoboards/StaffBoards/StaffBoards.vue'),
    meta: {
      title: 'Boards',
      icon: 'layout',
      permissions: ['infoboards.view_board']
    }
  },
  {
    path: 'drafts',
    name: 'infoboards-drafts' as const,
    component: () => import('@/views/Infoboards/Drafts/Drafts.vue'),
    meta: {
      title: 'Drafts',
      icon: 'edit',
      featureFlag: 'FEATURE_NEVERENDING_FEED',
      permissions: ['infoboards.view_board']
    }
  },
  {
    path: 'students-boards',
    name: 'infoboards-student-board' as const,
    component: () =>
      import('@/views/Infoboards/StudentsBoards/StudentsBoards.vue'),
    meta: {
      title: "Students' posts",
      icon: 'carry-out',
      permissions: ['infoboards.view_board']
    }
  },
  {
    path: 'alerts-and-notice',
    name: 'infoboards-alert-and-notice' as const,
    component: () => import('@/views/Infoboards/Messages/Messages.vue'),
    meta: {
      title: 'Alerts & notice',
      icon: 'safety',
      permissions: ['infoboards.view_board', 'infoboards.view_message']
    }
  },
  {
    path: 'schedule',
    name: 'infoboards-schedule' as const,
    component: () => import('@/views/Infoboards/Schedule/Schedule.vue'),
    meta: {
      title: 'Schedule',
      disabledWithFeatureFlag: 'FEATURE_NEVERENDING_FEED',
      icon: 'calendar',
      permissions: ['infoboards.view_board', 'infoboards.view_boardschedule']
    }
  },
  {
    path: 'settings',
    name: 'infoboards-settings' as const,
    component: () => import('@/views/Infoboards/Settings/Settings.vue'),
    meta: {
      title: 'Infoboards settings',
      icon: 'setting',
      permissions: [
        'infoboards.view_board',
        'infoboards.view_infoboardssettings'
      ]
    }
  }
] satisfies Route[]

const staffBoardsChildrenRoutes = [
  {
    path: 'create',
    name: 'infoboards-staff-board-add' as const,
    component: () =>
      import(
        '@/views/Infoboards/StaffBoards/StaffBoardsForm/StaffBoardsForm.vue'
      ),
    meta: {
      title: 'Create Board',
      permissions: ['infoboards.add_board']
    }
  },
  {
    path: ':id',
    name: 'infoboards-staff-board-details' as const,
    component: () =>
      import(
        '@/views/Infoboards/StaffBoards/StaffBoardsForm/StaffBoardsForm.vue'
      ),
    meta: {
      title: 'Board Details',
      permissions: ['infoboards.view_board']
    }
  }
] satisfies Route[]

const studentsBoardsChildrenRoutes = [
  {
    path: 'announcements/:itemId',
    name: 'student-board-announcement-details' as const,
    component: () =>
      import(
        '@/views/Infoboards/StudentsBoards/PostDetails/StudentAnnouncementDetails.vue'
      ),
    meta: {
      title: "Student's announcement details",
      permissions: ['students_announcement_widget.view_studentannouncement']
    }
  },
  {
    path: 'pics/:itemId',
    name: 'student-board-pic-details' as const,
    component: () =>
      import(
        '@/views/Infoboards/StudentsBoards/PostDetails/StudentPicDetails.vue'
      ),
    meta: {
      title: "Student's pic details",
      permissions: ['students_pic_widget.view_studentpic']
    }
  },
  {
    path: 'mystery-links/:itemId',
    name: 'student-board-mystery-link-details' as const,
    component: () =>
      import(
        '@/views/Infoboards/StudentsBoards/PostDetails/StudentMysteryLinkDetails.vue'
      ),
    meta: {
      title: "Student's mystery link details",
      permissions: ['mystery_link_widget.view_studentmysterylink']
    }
  }
] satisfies Route[]

const infoboardsNotTabRoutes = [
  {
    path: '',
    name: 'infoboards-tab' as const,
    component: () => import('@/components/base/BaseLayout.vue'),
    props: {
      routes: infoboardsTabRoutes
    },
    meta: {
      title: 'Infoboards'
    },
    redirect: () => getFirstPossibleRoute(infoboardsTabRoutes),
    children: infoboardsTabRoutes
  },
  {
    path: 'staff-boards',
    name: 'infoboards-student-boards' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Staff boards'
    },
    redirect: () => getFirstPossibleRoute(staffBoardsChildrenRoutes),
    children: staffBoardsChildrenRoutes
  },
  {
    path: 'students-boards',
    name: 'infoboards-students-boards' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Students boards'
    },
    redirect: () => getFirstPossibleRoute(studentsBoardsChildrenRoutes),
    children: studentsBoardsChildrenRoutes
  }
] satisfies Route[]

export const infoboardsRoutes = [
  {
    path: 'infoboards',
    name: 'infoboards' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Infoboards',
      icon: 'fund',
      permissions: ['infoboards.view_board']
    },
    redirect: () => getFirstPossibleRoute(infoboardsNotTabRoutes),
    children: infoboardsNotTabRoutes
  }
] satisfies Route[]
