<template>
  <FormItemWrapper
    type="input"
    :has-errors="hasErrors"
    :force-show-form="forceShowForm"
    :editable="editable"
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
    :query-selector-function="item => item.click()"
  >
    <template #text>
      <a-form-item
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
      >
        <div class="form-item-text">{{ formattedDate || emptyText }}</div>
      </a-form-item>
    </template>
    <template #input="{ toggleEditMode }">
      <a-form-model-item
        ref="formModelItem"
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
        :rules="customRules"
        :prop="prop"
      >
        <a-range-picker
          v-model="inputValue"
          v-bind="$attrs"
          data-cy="calendar-datepicker"
          v-on="$listeners"
          @openChange="value => (value ? null : toggleEditMode())"
        >
          <template #suffixIcon>
            <span>
              <a-icon type="calendar" />
            </span>
          </template>
        </a-range-picker>
      </a-form-model-item>
    </template>
  </FormItemWrapper>
</template>
<script>
import moment from 'moment'
import { defineComponent } from 'vue'

import FormItemWrapper from '@/components/common/forms/FormItemWrapper.vue'
import HInputMixin from '@/mixins/HInputMixin.vue'

export default defineComponent({
  components: { FormItemWrapper },
  mixins: [HInputMixin],

  props: {
    startDate: {
      type: [String, Object]
    },
    endDate: {
      type: [String, Object]
    }
  },

  emits: ['update:start-date', 'update:end-date'],

  computed: {
    inputValue: {
      set(value) {
        const startDate = value[0]
          ? moment(value[0]).set({ s: 0, ms: 0 })
          : value[0]
        const endDate = value[1]
          ? moment(value[1]).set({ s: 0, ms: 0 })
          : this.endDate
            ? startDate
            : value[1]

        this.$emit('update:start-date', startDate)
        this.$emit('update:end-date', endDate)
        if (this.prop) this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return [this.startDate, this.endDate]
      }
    },
    formattedDate() {
      const format = this.$attrs.format || 'YYYY-MM-DD HH:mm'

      if (this.inputValue[0] && this.inputValue[1]) {
        return `${moment(this.inputValue[0]).format(format)} - ${moment(
          this.inputValue[1]
        ).format(format)}`
      }
      return ''
    }
  }
})
</script>
<style lang="scss" scoped>
.form-item-wrapper {
  --date-picker-width: 100%;

  .ant-calendar-picker {
    width: var(--date-picker-width) !important;
  }
}
.form-item-wrapper {
  :deep(.ant-form-item-label) {
    & > label {
      &.ant-form-item-required {
        margin-left: 12px;
      }
    }
  }
}
</style>
