import { type Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/redirectHelpers'

const progressAlertsTabRoutes = [
  {
    path: 'current-potential-grades',
    name: 'current-potential-grades' as const,
    component: () =>
      import('@/views/ProgressAlerts/CurrentPotentialGradesView.vue'),
    meta: {
      title: 'Current potential grades',
      icon: 'bulb',
      permissions: ['permissions.view_potentialgradealert']
    }
  },
  {
    path: 'progress-comments',
    name: 'progress-comments' as const,
    component: () =>
      import('@/views/ProgressAlerts/ProgressCommentList/index.vue'),
    meta: {
      icon: 'profile',
      title: 'Potential grades weekly report',
      permissions: ['permissions.view_progress_comments_summary_view']
    }
  }
] satisfies Route[]

const progressAlertsChildRoutes = [
  {
    path: '',
    name: 'progress-alerts-tab' as const,
    component: () => import('@/components/base/BaseLayout.vue'),
    props: {
      routes: progressAlertsTabRoutes
    },
    meta: {
      title: 'Progress Alerts'
    },
    redirect: () => getFirstPossibleRoute(progressAlertsTabRoutes),
    children: progressAlertsTabRoutes
  }
] satisfies Route[]

export const progressAlertsRoutes = [
  {
    path: 'progress',
    name: 'progress-alerts' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Progress alerts',
      icon: 'line-chart',
      permissions: [
        'permissions.view_potentialgradealert',
        'permissions.view_progress_comments_summary_view'
      ]
    },
    redirect: () => getFirstPossibleRoute(progressAlertsChildRoutes),
    children: progressAlertsChildRoutes
  }
] satisfies Route[]
