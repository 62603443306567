import { type Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/redirectHelpers'

const studentTimetableChildRoutes = [
  {
    path: '',
    name: 'student-timetable-view' as const,
    component: () =>
      import('@/views/AdminPanel/Timetable/Student/StudentTimetableView.vue'),
    meta: {
      title: 'Student Timetable',
      permissions: ['permissions.view_students_timetable']
    }
  },
  {
    path: ':lessonId',
    name: 'student-timetable-activity' as const,
    component: () => import('@/views/TeacherPanel/TimetableDetails.vue'),
    meta: {
      title: 'Student Timetable',
      permissions: ['activities.view_lesson']
    }
  },
  {
    path: 'exam-planner/exam-attendance/:examSession/:exam',
    name: 'exam-attendance' as const,
    component: () => import('@/views/ExamPlanner/ExamAttendance/index.vue'),
    meta: {
      title: 'Exam attendance'
    }
  },
  {
    path: 'school-programmes/:programmeId/attendance-reports',
    name: 'ProgrammeAttendance' as const,
    component: () =>
      import(
        '@/views/Boarding/SchoolProgrammes/ProgrammeAttendance/ProgrammeAttendanceCollapse.vue'
      ),
    meta: {
      title: 'Programme attendance'
    }
  }
] satisfies Route[]

export const studentTimetableRoutes = [
  {
    path: 'timetable/student',
    name: 'student-timetable' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Student timetable',
      icon: 'calendar',
      permissions: ['permissions.view_students_timetable']
    },
    redirect: () => getFirstPossibleRoute(studentTimetableChildRoutes),
    children: studentTimetableChildRoutes
  }
] satisfies Route[]
