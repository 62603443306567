import { getFirstPossibleRoute } from '@/router/utils/redirectHelpers'
import { type Route } from '@/types/route'

const absenceChildrenRoutes = [
  {
    path: '',
    name: 'absence-list' as const,
    component: () => import('@/views/AdminPanel/Absence/AbsencesView.vue'),
    meta: {
      title: 'Absence'
    }
  }
] satisfies Route[]

export const absenceRoutes = [
  {
    path: 'absence',
    name: 'absence' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Absence',
      icon: 'container',
      permissions: ['attendances.view_absence']
    },
    redirect: () => getFirstPossibleRoute(absenceChildrenRoutes),
    children: absenceChildrenRoutes
  }
] satisfies Route[]
