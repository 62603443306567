<script>
import { Upload } from 'ant-design-vue'
import { defineComponent } from 'vue'

delete Upload.props.beforeUpload

export default defineComponent({
  extends: Upload,

  props: {
    name: {
      type: String,
      default: 'file'
    },
    headers: {
      type: Object,
      default: () => ({
        Authorization: window.localStorage.getItem('token')
      })
    }
  },

  methods: {
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$errorNotification('Only JPG and PNG are allowed')
      }
      const isLt3M = file.size / 1024 / 1024 < 3
      if (!isLt3M) {
        this.$errorNotification('Max image size is 3MB.')
      }
      return isJpgOrPng && isLt3M
    }
  }
})
</script>
