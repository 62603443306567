type TAEDraftSectionsGenerationEventType =
  | 'tae_draft_sections_task_running'
  | 'tae_draft_sections_task_succeeded'
  | 'tae_draft_sections_task_cancelled'

type TAEDraftSectionsGenerationStatusEnum =
  | 'running'
  | 'succeeded'
  | 'cancelled'
  | 'failed'

export type TAEDraftSectionsGenerationEventData = {
  event_type: TAEDraftSectionsGenerationEventType
  started_at: string
  status: TAEDraftSectionsGenerationStatusEnum
  message: string
}

export const taeDraftSectionsGenerationNotifications: Record<
  TAEDraftSectionsGenerationStatusEnum,
  string
> = {
  running: 'tae-draft-sections-generation-running',
  cancelled: 'tae-draft-sections-generation-cancelled',
  succeeded: 'tae-draft-sections-generation-succeeded',
  failed: 'tae-draft-sections-generation-failed'
} as const
