import type { Route } from '@/types/route'

export const timetableTabRoutes = [
  {
    path: ':timetableId/details/:rotaId?',
    component: () =>
      import('@/views/Planner/TimetableDetail/TimetableDetailsView.vue'),
    name: 'timetable-details' as const,
    meta: {
      title: 'Timetable',
      permissions: ['timetable.view_timetable']
    }
  },
  {
    path: ':timetableId/boarding-rota/:rotaId?',
    component: () =>
      import('@/views/Planner/BoardingRota/BoardingRotaView.vue'),
    name: 'timetable-boarding-rota' as const,
    meta: {
      title: 'Boarding Rotas',
      // TODO -change permission
      permissions: ['timetable.view_timetable']
    }
  }
] satisfies Route[]

const timetableNotTabRoutes = [
  {
    path: 'create',
    name: 'timetable-create' as const,
    component: () => import('@/views/Planner/TimetableForm/index.vue'),
    meta: {
      title: 'Timetable create',
      permissions: ['remote_generator.view_generationtask']
    }
  },
  {
    name: 'new-boarding-rota' as const,
    path: 'new-boarding-rota/:timetableId/:quarter/:rotaId?',
    component: () =>
      import('@/views/Planner/BoardingRota/NewBoardingRotaView.vue'),
    meta: {
      title: 'Generate new Boarding Rota',
      permissions: ['rota.add_rotageneration']
    }
  },
  {
    name: 'boarding-rota-task-details' as const,
    path: 'boarding-rota-task-details/:generationTaskId?',
    component: () =>
      import('@/views/Planner/BoardingRota/GenerationTaskDetails.vue'),
    meta: {
      permissions: ['rota.view_rotageneration']
    }
  },
  {
    name: 'timetable-suggestions-preview' as const,
    path: ':timetableId/preview/:taskId/suggestions/:suggestionId',
    component: () =>
      import(
        '@/views/Planner/DelphiSuggestionsPreview/TimetableSuggestionsPreviewView.vue'
      ),
    meta: {
      title: 'Timetable Changes Preview',
      permissions: ['timetable.view_timetable']
    }
  },
  {
    name: 'tae-generation-preview' as const,
    path: ':timetableId/tae-generation-preview/:taskId/',
    component: () =>
      import(
        '@/views/Planner/TaeGenerationPreview/TimetableTaeGenerationPreview.vue'
      ),
    meta: {
      title: 'Timetable Changes Preview',
      permissions: ['timetable.view_timetable']
    }
  },
  {
    path: ':timetableId/optimizations/:rotaId?',
    name: 'timetable-optimizations' as const,
    component: () => import('@/views/Planner/PotentialOptimizations/index.vue'),
    meta: {
      title: 'Timetable potential optimizations',
      permissions: ['remote_generator.view_generationtask']
    }
  },
  {
    path: ':timetableId/free-slots/',
    component: () => import('@/views/Planner/FreeSlots/index.vue'),
    name: 'timetable-free-slots' as const,
    meta: {
      title: 'Timetable free slots',
      permissions: ['draft_activities.view_draftactivity']
    }
  },
  {
    path: ':timetableId/rooms-import/',
    name: 'timetable-rooms-import' as const,
    component: () => import('@/views/Planner/RoomsImport/index.vue'),
    meta: {
      title: 'Import rooms from file',
      permissions: ['draft_activities.change_draftactivity']
    }
  },
  {
    path: 'generation/:generationTaskId',
    component: () =>
      import('@/views/Planner/GenerationProcess/GenerationProcessDetails.vue'),
    name: 'generation-process' as const,
    meta: {
      title: 'Timetable generation process',
      permissions: ['remote_generator.view_generationtask']
    }
  }
] satisfies Route[]

export const timetableRoutes = [
  {
    path: 'timetables',
    component: () => import('@/views/Planner/TimetableAndBoardingRotaView.vue'),
    name: 'timetable' as const,
    meta: {
      title: 'Timetable',
      permissions: ['timetable.view_timetable']
    },
    children: timetableTabRoutes
  },
  {
    path: 'timetables',
    name: 'timetables' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: timetableNotTabRoutes
  }
] satisfies Route[]
