import { getFirstPossibleRoute } from '@/router/utils/redirectHelpers'
import { type Route } from '@/types/route'

import { studentContactsRoutes } from './student-contacts'
import { studentsInnerRoutes } from './students-inner'

const studentsChildrenRoutes = [
  ...studentsInnerRoutes,
  ...studentContactsRoutes
] satisfies Route[]

export const studentsRoutes = [
  {
    path: '',
    name: 'students' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Students'
    },
    redirect: () => getFirstPossibleRoute(studentsChildrenRoutes),
    children: studentsChildrenRoutes
  }
] satisfies Route[]
