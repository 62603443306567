import FoldedSheetVue from '@/components/icons/FoldedSheet.vue'
import { type Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/redirectHelpers'

const schoolProgrammesChildRoutes = [
  {
    path: '',
    name: 'school-programme-list' as const,
    component: () =>
      import(
        '@/views/Boarding/SchoolProgrammes/SchoolProgrammesList/index.vue'
      ),
    meta: {
      title: 'SchoolProgrammes'
    }
  },
  {
    path: 'add',
    name: 'create-school-programme' as const,
    component: () =>
      import(
        '@/views/Boarding/SchoolProgrammes/SchoolProgrammesForm/SchoolProgrammesForm.vue'
      ),
    meta: {
      title: 'Create School Programme',
      permissions: ['school_programmes.add_schoolprogramme']
    }
  },
  {
    path: ':id',
    name: 'edit-school-programme' as const,
    component: () =>
      import(
        '@/views/Boarding/SchoolProgrammes/SchoolProgrammesForm/SchoolProgrammesForm.vue'
      ),
    meta: {
      title: 'Edit School Programme',
      permissions: ['school_programmes.change_schoolprogramme']
    }
  }
] satisfies Route[]

export const schoolProgrammesRoutes = [
  {
    path: 'school-programmes',
    name: 'school-programme' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'School Programmes',
      iconComponent: FoldedSheetVue,
      permissions: ['school_programmes.view_schoolprogramme']
    },
    redirect: () => getFirstPossibleRoute(schoolProgrammesChildRoutes),
    children: schoolProgrammesChildRoutes
  }
] satisfies Route[]
